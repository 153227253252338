export const SET_WELL_PROFILES_DATES = 'SET_WELL_PROFILES_DATES';
export const SET_WELL_PROFILES_DEPTH = 'SET_WELL_PROFILES_DEPTH';
export const SET_WELL_PROFILE_CHART_MODE = 'SET_WELL_PROFILE_CHART_MODE';
export const GET_LIVE_DATA = 'GET_LIVE_DATA';
export const GET_LIVE_DATA_ALERT = 'GET_LIVE_DATA_ALERT';
export const GET_FREQUENT_LIVE_DATA = 'GET_FREQUENT_LIVE_DATA';
export const UPDATE_WELL_PROFILE_TIME = 'UPDATE_WELL_PROFILE_TIME';
export const GET_FORCE_ROP = 'GET_FORCE_ROP';
export const SET_WELL_PROFILES_DATA = 'SET_WELL_PROFILES_DATA';
export const TOGGLE_HIDE_ANNOTATATIONS = 'TOGGLE_HIDE_ANNOTATATIONS';
export const SET_STATUS_API = 'SET_STATUS_API';
export const SET_IMPORT_CONSTANTS_LISTS = 'SET_IMPORT_CONSTANTS_LISTS';
export const GET_SETTING = 'GET_SETTING';
export const GET_SETTING_LOGO = 'GET_SETTING_LOGO';
export const SERVER_CONNECTION_HAS_FAILED = 'SERVER_CONNECTION_HAS_FAILED';
export const SERVER_IS_DOWN = 'SERVER_IS_DOWN';
export const GET_PUMPS_DATA = 'GET_PUMPS_DATA';
export const GET_ALL_TRACES = 'GET_ALL_TRACES';
export const IS_ADD_UNIT = 'IS_ADD_UNIT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_USER_EXPORT_HISTORIES = 'GET_USER_EXPORT_HISTORIES';
export const GET_INSTALLED_TRACES = 'GET_INSTALLED_TRACES';
export const GET_ALL_UNITS = 'GET_ALL_UNITS';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const GET_ALL_COLORS = 'GET_ALL_COLORS';
export const GET_SENSORS = 'GET_SENSORS';
export const GET_REDIS_AUTO_DEPTH = 'GET_REDIS_AUTO_DEPTH';
export const GET_TRACK_TRACES = 'GET_TRACK_TRACES';
export const GET_TRACK_TRACES_DRILLER = 'GET_TRACK_TRACES_DRILLER';
export const GET_TRACK_TRACES_TANKS = 'GET_TRACK_TRACES_TANKS';
export const GET_DRILLING_TRACES_TANKS = 'GET_DRILLING_TRACES_TANKS';
export const UPDATE_DRILLING_TRACES_TANKS = 'UPDATE_DRILLING_TRACES_TANKS';
export const GET_GAUGES_TRACES_DRILLER = 'GET_GAUGES_TRACES_DRILLER';
export const GET_DRILL_STRING_LENGTH = 'GET_DRILL_STRING_LENGTH';
export const GET_AUTO_DEPTH_REFERENCE_WEIGHT = 'GET_AUTO_DEPTH_REFERENCE_WEIGHT';
export const GET_TALLY_MODE = 'GET_TALLY_MODE';
export const GET_TALLY_STAND_STATUS = 'GET_TALLY_STAND_STATUS';
export const GET_TALLY_STAND_NUMBER = 'GET_TALLY_STAND_NUMBER';
export const UPDATE_ALERT_MESSAGE = 'UPDATE_ALERT_MESSAGE';
export const SET_DELAY_CHART = 'SET_DELAY_CHART';
export const GET_VIEWS = 'GET_VIEWS';
export const GET_DRILLER_VIEWS = 'GET_DRILLER_VIEWS';
export const GET_SELECTED_DRILLER_VIEW = 'GET_SELECTED_DRILLER_VIEW';
export const GET_VIEW_DRILLER_ALERT = 'GET_VIEW_DRILLER_ALERT';
export const GET_VIEW = 'GET_VIEW';
export const SET_VIEW_ID = 'SET_VIEW_ID';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const UPDATE_SETTING_THEME = 'UPDATE_SETTING_THEME';
export const SET_LOADING = 'SET_LOADING';
export const GET_ALL_MEMOS = 'GET_ALL_MEMOS';
export const GET_ALL_MEMOS_TAGS = 'GET_ALL_MEMOS_TAGS';
export const TOGGLE_MEMOS_FILTER = 'TOGGLE_MEMOS_FILTER';
export const SET_FILTERED_MEMOS = 'SET_FILTERED_MEMOS';
export const SET_FILTERED_DATA = 'SET_FILTERED_DATA';
export const FETCHED_FILTERED_DATA = 'FETCHED_FILTERED_DATA';
export const FETCHED_REPORT_DATA = 'FETCHED_REPORT_DATA';
export const IS_EDIT_HALF_GAUGE = 'IS_EDIT_HALF_GAUGE';
export const SET_PLC_STATUS = 'SET_PLC_STATUS';
export const SET_PLC_SAVE_SCALE = 'SET_PLC_SAVE_SCALE';
export const READ_PLC_SCALE_HISTORY = 'READ_PLC_SCALE_HISTORY';
export const SET_MIN_AND_MAX = 'SET_MIN_AND_MAX';
export const SET_MIN_AND_MAX_SCALED = 'SET_MIN_AND_MAX_SCALED';
export const SET_SCALED_LIVE_DATA = 'SET_SCALED_LIVE_DATA';
export const GET_ALL_EMOJIS = 'GET_ALL_EMOJIS';
export const HISTORICAL = 'HISTORICAL';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_FINISH_PROJECT = 'SET_FINISH_PROJECT';
export const SET_WELL_PROFILE_FILTERED_RANGE = 'SET_WELL_PROFILE_FILTERED_RANGE';
export const GET_ALL_UNITS_CATEGORY = 'GET_ALL_UNITS_CATEGORY';
export const SET_WELL_PROFILE_FILTERED_DATES = 'SET_WELL_PROFILE_FILTERED_DATES';
export const IS_RANGE_FILTERED_DATE_CHANGED = 'IS_RANGE_FILTERED_DATE_CHANGED';
export const TOGGLE_FOCUS_MODE = 'TOGGLE_FOCUS_MODE';
export const GET_CHART_LIMIT_NUMBER = 'GET_CHART_LIMIT_NUMBER';
export const DELETE_TRACE_CHART_SET = 'DELETE_TRACE_CHART_SET';
export const DELETE_DRILLER_TRACE_CHART_SET = 'DELETE_DRILLER_TRACE_CHART_SET';
export const SET_SELECTED_LINE = 'SET_SELECTED_LINE';
export const GET_THREADS = 'GET_THREADS';
export const GET_TOOLS = 'GET_TOOLS';
export const GET_TOOLS_INSERTED = 'GET_TOOLS_INSERTED';
export const GET_CASING_TOOLS = 'GET_CASING_TOOLS';
export const GET_INSERTED_CASING_TOOLS = 'GET_INSERTED_CASING_TOOLS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const TOGGLE_REPORT_LOADING = 'TOGGLE_REPORT_LOADING';
export const TOGGLE_AUDIBLE_ALARM = 'TOGGLE_AUDIBLE_ALARM';
export const SET_A_MENU_OPEN = 'SET_A_MENU_OPEN';
export const CALL_AUTO_REALOD = 'CALL_AUTO_REALOD';
export const SET_OVERLAY_LOADING = 'SET_OVERLAY_LOADING';
export const SET_HISTORICAL_CHART_VALUE = 'SET_HISTORICAL_CHART_VALUE';
export const SEND_MUD_SAMPLING = 'SEND_MUD_SAMPLING';
export const SET_LIVE_CHART_VALUE = 'SET_LIVE_CHART_VALUE';
export const SET_DRILLER_MENU_SYNC_CHARTS_DATA = 'SET_DRILLER_MENU_SYNC_CHARTS_DATA';
export const GET_DRILLER_LOGS = 'GET_DRILLER_LOGS';
export const SET_WOB_VALUE_STATUS = 'SET_WOB_VALUE_STATUS';
export const GET_WOB_VALUE = 'GET_WOB_VALUE';
export const GET_USER_STORAGE = 'GET_USER_STORAGE';
export const UPDATE_USER_STORAGE = 'UPDATE_USER_STORAGE';
export const SET_SAFETY_TOOLS_STATUS = 'SET_SAFETY_TOOLS_STATUS';
