import * as types from '../types';

const initialState = {
  threads: [],
  tools: [],
  toolInserted: [],
  casingTools: [],
  insertedCasingTools: [],
};

export default function addTools(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_THREADS:
      return {
        ...state,
        threads: payload,
      };
    case types.GET_TOOLS:
      return {
        ...state,
        tools: payload,
      };
    case types.GET_TOOLS_INSERTED:
      return {
        ...state,
        toolInserted: payload,
      };
    case types.GET_CASING_TOOLS:
      return {
        ...state,
        casingTools: payload,
      };
    case types.GET_INSERTED_CASING_TOOLS:
      return {
        ...state,
        insertedCasingTools: payload,
      };
    default:
      return state;
  }
}
