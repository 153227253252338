import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const LogsSection = () => {
  const { logs } = useSelector((state) => state.drillerMenu);

  const spiltText = (message, color) => {
    // The API time string
    const apiTimeString = message.split('#')[0];
    const currentYear = dayjs().year();
    const formattedTimeString = `${currentYear} ${apiTimeString}`;

    const utcDate = new Date(`${formattedTimeString} UTC`);
    // Convert the Date object to a local time string
    const localDateString = utcDate.toLocaleString();
    const finalLocalTimeFormat = dayjs(localDateString).format('MMM DD HH:mm:ss');

    return (
      <div className="flex items-center gap-x-1D">
        <span>{finalLocalTimeFormat}</span>
        <span style={{ color }} className="pl-1">
          #{message.split('#')[1]}
        </span>
      </div>
    );
  };

  return (
    <ul className="space-y-1 text-xs font-semibold pr-1 capitalize">
      {logs?.map((data) => (
        <li key={data.id} className="space-x-1">
          {spiltText(data.message, data.color)}
        </li>
      ))}
    </ul>
  );
};

export default LogsSection;
