import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import _ from 'lodash';
import * as types from '../types';
import { getDrillerLogs } from './drillerMenu';

export const getThreads = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_THREADS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllThreads(respone, error);
};

export const getTools = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TOOLS,
      payload: _.map(res?.data, (item, index) => ({ ...item, index: res?.data?.length - index || 0 })),
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getTools(respone, error);
};

export const getToolsInserted = () => async (dispatch) => {
  const respone = (res) => {
    dispatch(getDrillerLogs());
    dispatch({
      type: types.GET_TOOLS_INSERTED,
      payload: _.map(res?.data, (item, index) => ({ ...item, index: res?.data?.length - index || 0 })),
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getToolsInserted(respone, error);
};

export const getAllCasingTools = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_CASING_TOOLS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getAllCasingTools(respone, error);
};

export const getInsertedCasingTools = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_INSERTED_CASING_TOOLS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getCasingToolsInserted(respone, error);
};
